import React from "react"
import SEO from "../components/seo"
import { FaEnvelope } from 'react-icons/fa'

import "../scss/aboutus.scss"

import Image1 from "../assets/img/youpalGroup/aboutUs/img1.png"
import Image2 from "../assets/img/youpalGroup/aboutUs/img2.png"
import Image3 from "../assets/img/youpalGroup/aboutUs/img3.png"
import Image4 from "../assets/img/youpalGroup/aboutUs/img4.png"
import appendScript from '../utils/appendScript';

const AboutUsPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=30", "contact-us-mautic-internal-form");

  return (
    <div>
      <SEO title="About Us" keywords={[`youpal`, `youpal group`]} />
      <section className="container aboutUsPageSection">
        <p>
        The Youpal Group story isn’t that different from any other company. What is different, however, is that Youpal is creating history.
        </p>
        <p>
        This needs courage and a vibrant goal. Creating history requires spirit and sparkle. Karl Leahlander and Ruben Teijeiro set fire to the spark when they founded Youpal in Sweden in 2016. They were shortly joined by James Baker-Duly. The trio’s humanity and charisma permeate the whole company consistently. With their passion and dedication, Youpal has grown into an innovation and development hotpsot. Its presence now spans 3 continents and 8 countries, but its energy is worldwide. It also operates in just about any field and industry in existence.
        </p>
        <img className="contactUsHeaderImg" src={Image1} />
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col imgCol">
            <img className="sideImg" src={Image2} />
          </div>
          <div className="col sideContent text-left">
            <p>
            Youpal is capable of new achievements and constant development in a very short period of time. This is because the team is united under a single vision, through multiple goals. We proudly call our foundation stones: digital infrastructure. The base is rigid enough to build on the ideas, desires and concepts. 
            </p>
            <p>
            Initially, the company was founded with an IT focus. As IT permeates through so many industries, the vision of the company evolved. It now focuses on the virtualisation and technological advancement of every industry and business, regardless of the size and type.
            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col sideContent text-left">
            <p>
            Creating a roadmap with solutions designed to meet specific business requirements is now what Youpal is focused on. Your endpoint is our venture, irrespective of your starting point. Without You, there is no Youpal, as you are its most important component. Our business is to be your Pal in enabling your digital infrastructure culture. That is the key to every single door we open. 
            </p>
          </div>
          <div className="col imgCol">
            <img className="sideImg" src={Image3} />
          </div>
        </div>
      </section> 

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col imgCol">
            <img className="sideImg" src={Image4} />
          </div>
          <div className="col sideContent text-left">
            <p>
            We believe in technology-agnostic solutions to create a culture that understands the purpose and goal of any digital implementation, installation, strategy, or change. This is Youpal’s strategy to make you part of the foundation and infrastructure of any business domain.
            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row contactBox">
            <div className="col">
                <h2>Contact us</h2>
                <div className="sendLinkContainer">
                  <div className="formDiv">
                      <FaEnvelope size={25} />
                      {/* Mautic form */}
                      <div id='contact-us-mautic-internal-form'/>
                  </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default AboutUsPage
